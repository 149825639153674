import React, { Component } from "react";
import { navigate, graphql } from "gatsby";
import { connect } from "react-redux";
import { Button } from "@instructure/ui-buttons";
import IconArrowOpenEnd from "@instructure/ui-icons/lib/IconArrowOpenEndSolid";
import IconArrowOpenStart from "@instructure/ui-icons/lib/IconArrowOpenStartSolid";

import Layout from "../../../../components/layout";
// import QuestionHeader from "../../../components/question-header";
import SEO from "../../../../components/seo";
import styles from "./index.module.css";
import { setCurrentModule, setCurrentStep } from "../../../../state/actions";
import { func, shape, object } from "prop-types";

class NormalcyIndex extends Component {
  state = {
    hasSubmitted: false,
    hasSeenFeedback: false
  };

  static propTypes = {
    handleNavigate: func,
    goToStep: func,
    data: shape({
      file: shape({
        childImageSharp: shape({
          fixed: object
        })
      })
    })
  };

  componentDidMount() {
    // Make sure that the progress bar always shows
    this.props.handleNavigate(2);
    this.props.goToStep(2);
  }

  goForward = () => {
    navigate("/modules/travel/rights/classification-activity");
  };

  goBack = () => {
    navigate("/modules/travel/introduction/question");
  };

  render() {
    return (
      <Layout isModule>
        <SEO
          title="Module - Travel - Rights"
          keywords={["utah foster care", "foster care"]}
        />
        <div className={styles.moduleGridContainer}>
          <div className={styles.largeText}>
            <p>
              The first thing you will need to understand is who retains which
              rights with regards to travel.
            </p>
          </div>
          <div className={styles.backButtonArea}>
            <Button
              onClick={this.goBack}
              variant="success"
              icon={IconArrowOpenStart}
            />
          </div>
          <div className={styles.forwardButtonArea}>
            <Button
              onClick={this.goForward}
              variant="success"
              icon={IconArrowOpenEnd}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state, ownProps) => ({
  ...ownProps
});

const dispatchToProps = dispatch => {
  return {
    handleNavigate(moduleId) {
      dispatch(setCurrentModule(moduleId));
    },
    goToStep(stepId, navigateTo) {
      dispatch(setCurrentStep(stepId));
      if (navigateTo) {
        navigate(navigateTo);
      }
    }
  };
};

export const query = graphql`
  query {
    family: file(name: { eq: "family" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    disney: file(name: { eq: "disney-castle" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default connect(
  stateToProps,
  dispatchToProps
)(NormalcyIndex);
